









































import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { KPageHeader, KText },
  name: 'LandingNavbar',
  setup() {
    const navigations = [
      {
        text: 'Utama',
        to: '/'
      },
      {
        text: 'Industri',
        to: '/industri'
      },
      {
        text: 'Produk',
        to: '/produk'
      }
    ];

    return {
      navigations
    };
  }
});
